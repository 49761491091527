import React, { Component } from "react"
import styled from "styled-components"
import viljuskari from "../config/VIljuskariData"
import logo from "../images/gatsby-icon.png"
import { Link } from "gatsby"
import En from "../locales/en"
import Sr from "../locales/sr"

const Container = styled.div`
  background: #262735;
  min-width: 1000px;
`

const Content = styled.div`
  width: calc(100vw - 200px - 200px);
  margin: auto;
  padding-top: 150px;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 50px;
  p {
    margin: 0 !important;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: #3c3d4a;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 950px;
  p {
    white-space: nowrap;
    font-size: 13px;
    opacity: 0.5;
    color: white;
    width: 145px;
    border-left: 1px solid #808080;
    padding: 7px 0 7px 7px;
    text-align: center;
    :first-child {
      border-left: 0;
      width: 295px;
      padding: 7px 0 7px 10px;
      text-align: start;
    }
    :nth-child(2) {
      width: 85px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const TextRow = styled.p`
  width: 580px !important;
  text-align: center;
`

const TextRowBig = styled.p`
  width: 730px !important;
  text-align: center;
`

const CustomP = styled.p`
  width: 116px;
  white-space: nowrap;
  font-size: 13px;
  opacity: 0.5;
  color: white;
  border-left: 1px solid #808080;
  padding: 7px 0 7px 7px;
  text-align: center;
`

const Headline = styled.h3`
  color: white;
  font-size: 20px;
  width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 50px;
`

const TextHalfRow = styled.p`
  width: 290px !important;
  text-align: center;
`

const Navigation = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #3c3d4a;
`

const Image = styled.div`
  img {
    margin-bottom: 0;
    width: 70%;
    float: right;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 950px;
  margin: auto;
`

const Left = styled.div`
  h1 {
    font-size: 80px;
    letter-spacing: 5px;
    color: white;
    margin-bottom: 150px;
  }
`

const Right = styled.div`
  //width: 50%;
`

const LngBtn = styled.div`
  margin-bottom: 0 !important;
  button {
    border: none;
    background: transparent;
    outline: none;
    color: white;
    cursor: pointer;
  }
`

const TobarRes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  background: #262735;
  padding: 0 30px;
  height: 72px;
`

const LogoRes = styled.img`
  height: 32px;
  width: 56px;
  margin-bottom: 0 !important;
  z-index: 1000;
`

const MenuRes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background: #272735;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    font-family: "Cinzel", serif;
    color: white;
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
    text-align: center;
    margin: 45px 0;
  }
  button {
    background: transparent;
    border: none;
    outline: none;
    color: #ec4039;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    cursor: pointer;
  }
`

const TopbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #262735;
  padding: 0 90px;
  height: 150px;
`

const Logo = styled.img`
  height: 74px;
  width: 133px;
  margin-bottom: 0 !important;
`

const Menu = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1000px;
  a {
    cursor: pointer;
    text-decoration: none;
    color: white !important;
  }
`

class ViljuskariPage extends Component {
  state = {
    activeKey: "Elektro Viljuskari",
    language: "",
    sirina: "",
    display: false,
  }

  handleClick = activeKey => {
    this.setState({ activeKey })
  }

  componentDidMount = () => {
    let language = localStorage.getItem("StimLang")
    this.setState({ language: language })
    let sirina = window.innerWidth
    this.setState({ sirina: sirina })
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    // window.removeEventListener("resize", this.updateWindowDimensions);
  }

  engLanguage = () => {
    localStorage.setItem("StimLang", "eng")
    this.setState({ language: "eng" })
  }

  srbLanguage = () => {
    localStorage.setItem("StimLang", "srb")
    this.setState({ language: "srb" })
  }

  updateWindowDimensions = () => {
    this.setState({
      sirina: window.innerWidth,
    })
  }

  openNav = () => {
    this.setState({ display: !this.state.display })
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  closeNav = () => {
    this.setState({ display: false })
  }

  render() {
    let { activeKey } = this.state
    let display = this.state.display
    let lang = this.state.language
    let sirina = this.state.sirina
    //lang files
    let menuEng = En.meni
    let menuSrb = Sr.meni

    return (
      <Container>
        {/*<Topbar />*/}
        {sirina <= 1000 ? (
          <TobarRes>
            <LogoRes src={logo} />
            <div
              className={this.state.active ? "menuBtn" : "active"}
              onClick={this.openNav}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            {display === true ? (
              <>
                <MenuRes>
                  <Link to="/" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.pocetna : menuSrb.pocetna}
                  </Link>
                  <Link to="/ViljuskariPages" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.onama : menuSrb.onama}
                  </Link>
                  <Link to="/#viljuskari" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.viljuskari : menuSrb.viljuskari}
                  </Link>
                  <Link to="/#ostali" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.proizvodi : menuSrb.proizvodi}
                  </Link>
                  <Link to="/#prerada" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.prerada : menuSrb.prerada}
                  </Link>
                  <Link to="/#kontakt" activeClassName="activeBtn">
                    {lang === "eng" ? menuEng.kontakt : menuSrb.kontakt}
                  </Link>
                  {lang === "srb" ? (
                    <button className="test" onClick={() => this.engLanguage()}>
                      EN
                    </button>
                  ) : (
                    <button className="test" onClick={() => this.srbLanguage()}>
                      SR
                    </button>
                  )}
                </MenuRes>
              </>
            ) : null}
          </TobarRes>
        ) : (
          <TopbarContainer>
            <Logo src={logo} />
            <Menu>
              <Link to="/">
                {lang === "eng" ? menuEng.pocetna : menuSrb.pocetna}
              </Link>
              <Link to="/#onama">
                {lang === "eng" ? menuEng.onama : menuSrb.onama}
              </Link>
              <Link to="/#viljuskari">
                {lang === "eng" ? menuEng.viljuskari : menuSrb.viljuskari}
              </Link>
              <Link to="/#ostali">
                {lang === "eng" ? menuEng.proizvodi : menuSrb.proizvodi}
              </Link>
              <Link to="/#prerada">
                {lang === "eng" ? menuEng.prerada : menuSrb.prerada}
              </Link>
              <Link to="/#kontakt">
                {lang === "eng" ? menuEng.kontakt : menuSrb.kontakt}
              </Link>
            </Menu>

            <LngBtn>
              <div>
                <div className="langButton">
                  {lang === "srb" ? (
                    <button className="test" onClick={() => this.engLanguage()}>
                      EN
                    </button>
                  ) : (
                    <button className="test" onClick={() => this.srbLanguage()}>
                      SR
                    </button>
                  )}
                </div>
              </div>
            </LngBtn>
          </TopbarContainer>
        )}
        <Content>
          <Top>
            <Left>
              <h1>Viljuskari</h1>
              <Navigation>
                {Object.keys(viljuskari).map(key => {
                  const { name } = viljuskari[key]
                  return (
                    <div
                      onClick={() => this.handleClick(key)}
                      className={`masine ${activeKey === key &&
                        "activeMasine"}`}
                    >
                      <p>{name}</p>
                    </div>
                  )
                })}
              </Navigation>
            </Left>
            <Right>
              <Image>
                <img alt="" src={viljuskari[activeKey].src} />
              </Image>
            </Right>
          </Top>
          {activeKey === "Elektro Viljuskari" ? (
            <Table>
              {/*Osnovne Karakteristike*/}
              <Headline>Osnovne karakteristike</Headline>
              <Row>
                <p>Tip</p>
                <p>{""}</p>
                <p>EV 1250.30</p>
                <p>EV 1600.30</p>
                <p>EV 2000.30</p>
                <p>EV 2500.30</p>
              </Row>
              <Row>
                <p>Nosivost</p>
                <p>kg</p>
                <p>1250</p>
                <p>1600</p>
                <p>2000</p>
                <p>2500</p>
              </Row>
              <Row>
                <p>Težište tereta</p>
                <p>mm</p>
                <p>500</p>
                <p>500</p>
                <p>500</p>
                <p>500</p>
              </Row>
              <Row>
                <p>Uređaj za pokretanje</p>
                <p>{""}</p>
                <p>Baterija</p>
                <p>Baterija</p>
                <p>Baterija</p>
                <p>Baterija</p>
              </Row>
              <Row>
                <p>Upravljanje sistema</p>
                <p>{""}</p>
                <TextRow>sa sedišta vozača</TextRow>
              </Row>
              <Row>
                <p>Gume</p>
                <p>{""}</p>
                <TextRow>super elastik</TextRow>
              </Row>
              <Row>
                <p>Točkovi</p>
                <p>{""}</p>
                <TextRow>4 komada</TextRow>
              </Row>
              {/*Dimenzije*/}
              <Headline>Dimenzije</Headline>
              <Row>
                <p>Maximalna visina dizanja</p>
                <p>mm</p>
                <TextRow>3000</TextRow>
              </Row>
              <Row>
                <p>Normalna visina dizanja</p>
                <p>mm</p>
                <TextRow>70</TextRow>
              </Row>
              <Row>
                <p>Viljuške</p>
                <p>mm</p>
                <TextRow>40x100x1150</TextRow>
              </Row>
              <Row>
                <p>Nagib krana napred / nazad</p>
                <p>mm</p>
                <TextHalfRow>4*30' / 8°</TextHalfRow>
                <TextHalfRow>4*30' / 9°</TextHalfRow>
              </Row>
              <Row>
                <p>Visina bez viljuški</p>
                <p>mm</p>
                <p>1885</p>
                <p>1995</p>
                <p>2160</p>
                <p>2275</p>
              </Row>
              <Row>
                <p>Širina viljuškara</p>
                <p>mm</p>
                <TextHalfRow>995(1060)</TextHalfRow>
                <TextHalfRow>1185</TextHalfRow>
              </Row>
              <Row>
                <p>Visina spuštenog krana</p>
                <p>mm</p>
                <TextRow>2200</TextRow>
              </Row>
              <Row>
                <p>Visina podignutog krana</p>
                <p>mm</p>
                <TextRow>3900max</TextRow>
              </Row>
              <Row>
                <p>Visina kabine</p>
                <p>mm</p>
                <TextHalfRow>2150</TextHalfRow>
                <TextHalfRow>2190</TextHalfRow>
              </Row>
              <Row>
                <p>Visina sedišta</p>
                <p>mm</p>
                <TextHalfRow>1025</TextHalfRow>
                <TextHalfRow>1055</TextHalfRow>
              </Row>
              <Row>
                <p>Spoljni radijus okretanja</p>
                <p>mm</p>
                <p>1650</p>
                <p>1750</p>
                <p>1920</p>
                <p>1950</p>
              </Row>
              <Row>
                <p>Raspon od osovine do viljuški</p>
                <p>mm</p>
                <p>337</p>
                <p>337</p>
                <p>415</p>
                <p>425</p>
              </Row>
              <Row>
                <p>Prostor za rad između paleta</p>
                <p>mm</p>
                <TextRow>
                  za palete dimenzije 800x1200 i 1000x1200 Ast=3512mm
                </TextRow>
              </Row>
              {/*Preformanse*/}
              <Headline>Preformanse</Headline>
              <Row>
                <p>Brzina - kretanja utovaren/istovaren</p>
                <p>km/h</p>
                <TextHalfRow>13/15</TextHalfRow>
                <TextHalfRow>11/13</TextHalfRow>
              </Row>
              <Row>
                <p>Brzina - dizanja utovaren/istovaren</p>
                <p>cm/s</p>
                <TextHalfRow>32/42</TextHalfRow>
                <TextHalfRow>30/38</TextHalfRow>
              </Row>
              <Row>
                <p>Brzina - opadajuća brzina utovaren/istovaren</p>
                <p>cm/s</p>
                <TextHalfRow>60max/20min</TextHalfRow>
                <TextHalfRow>60max/32min</TextHalfRow>
              </Row>
              <Row>
                <p>Prosečna snaga vuče utovaren/istovaren</p>
                <p>kN</p>
                <TextRow>(S2=60min) 1.7</TextRow>
              </Row>
              <Row>
                <p>Maximalna snaga vuče utovaren/istovaren</p>
                <p>kN</p>
                <TextHalfRow>4</TextHalfRow>
                <TextHalfRow>7</TextHalfRow>
              </Row>
              <Row>
                <p>Radni nagib - S2=30 min utovaren/istovaren</p>
                <p>%</p>
                <TextHalfRow>4/8</TextHalfRow>
                <TextHalfRow>4/6</TextHalfRow>
              </Row>
              <Row>
                <p>Radni nagib - S2=5 min utovaren/istovaren</p>
                <p>%</p>
                <TextHalfRow>10-14</TextHalfRow>
                <p>12-18</p>
                <p>8-16</p>
              </Row>
              <Row>
                <p>Maximalni nagib utovaren/istovaren (S2=1min)</p>
                <p>%</p>
                <TextHalfRow>20/24 max</TextHalfRow>
                <p>21-23 max</p>
                <p>20-21 max</p>
              </Row>
              {/*Okvirne Karakteristike*/}
              <Headline>Okvirne Karakteristike</Headline>
              <Row>
                <p>Gume - napred/nazad</p>
                <p>{""}</p>
                <TextRow>2/2</TextRow>
              </Row>
              <Row>
                <p>Gume - dimenzije napred</p>
                <p>{""}</p>
                <TextHalfRow>18x7-8</TextHalfRow>
                <TextHalfRow>23x9-10</TextHalfRow>
              </Row>
              <Row>
                <p>Gume - dimenzije nazad</p>
                <p>{""}</p>
                <TextHalfRow>16x6-8</TextHalfRow>
                <TextHalfRow>18x7-8</TextHalfRow>
              </Row>
              <Row>
                <p>Rastojanje izmedu osovina točkova</p>
                <p>mm</p>
                <p>1290</p>
                <p>1440</p>
                <TextHalfRow>1445</TextHalfRow>
              </Row>
              <Row>
                <p>Rastojanje od centra točka do krana</p>
                <p>mm</p>
                <TextHalfRow>886/780</TextHalfRow>
                <TextHalfRow>960/890</TextHalfRow>
              </Row>
              <Row>
                <p>Najniži položaj utovarenog/istovarenog vilj.</p>
                <p>mm</p>
                <TextHalfRow>88</TextHalfRow>
                <TextHalfRow>110</TextHalfRow>
              </Row>
              <Row>
                <p>Rastojanje između osovine točkova (istovar)</p>
                <p>mm</p>
                <TextHalfRow>105</TextHalfRow>
                <TextHalfRow>130</TextHalfRow>
              </Row>
              <Row>
                <p>Nožna kočnica</p>
                <p>{""}</p>
                <TextRow>hidraulična</TextRow>
              </Row>
              <Row>
                <p>Ručna kočnica</p>
                <p>{""}</p>
                <TextRow>mehanička</TextRow>
              </Row>
              {/*voznja*/}
              <Headline>Vožnja</Headline>
              <Row>
                <p>Akumulator - napon/kapacitet</p>
                <p>V/Ah</p>
                <p>48/400 48/480</p>
                <p>48/500 48/600</p>
                <p>80/400 80/480</p>
                <p>80/400 80/480</p>
              </Row>
              <Row>
                <p>Akumulator - težina</p>
                <p>kg</p>
                <p>48/400 48/480</p>
                <p>48/500 48/600</p>
                <p>80/400 80/480</p>
                <p>80/400 80/480</p>
              </Row>
              <Row>
                <p>Akumulator - snaga</p>
                <p>kW</p>
                <TextHalfRow>7.5</TextHalfRow>
                <TextHalfRow>9</TextHalfRow>
              </Row>
              <Row>
                <p>Elektro motor</p>
                <p>kW</p>
                <TextRow>10</TextRow>
              </Row>
              <Row>
                <p>Brzina kontrole tip/postupak</p>
                <p>{""}</p>
                <TextRow>elektronski / postepeno</TextRow>
              </Row>
              <Row>
                <p>Prenošenje(tip/brzina napred/nazad)</p>
                <p>{""}</p>
                <TextRow>mehaničko</TextRow>
              </Row>
              <Row>
                <p>Prosečan radni pritisak za hidrauličnu vezu</p>
                <p>MP-a</p>
                <TextRow>max-17</TextRow>
              </Row>
              <Row>
                <p>Stepen buke</p>
                <p>dB</p>
                <TextRow>70... 72</TextRow>
              </Row>
              {/*  ostalo*/}
              <Headline>Ostale Karakteristike</Headline>
              <Row>
                <p> Dimenzije viljuskara sa okrenutim viljuškama</p>
                <p>{""}</p>
                <TextRow>{""}</TextRow>
              </Row>
              <Row>
                <p>Širina viljuskara sa okrenutim viljuškama</p>
                <p>mm</p>
                <TextRow>1200</TextRow>
              </Row>
              <Row>
                <p>Dužina od kolica do okretne glave</p>
                <p>mm</p>
                <TextRow>950</TextRow>
              </Row>
            </Table>
          ) : null}
          {activeKey === "Dizel Viljuskari" ? (
            <Table>
              {/*Osnovne Karakteristike*/}
              <Headline>Osnovne Karakteristike</Headline>
              <Row>
                <p>Tip</p>
                <p>{""}</p>
                <CustomP>DV 2000 30</CustomP>
                <CustomP>DV 2500 30</CustomP>
                <CustomP>DV 3000 30</CustomP>
                <CustomP>DV 3500 30</CustomP>
                <CustomP>DV 4000 30</CustomP>
              </Row>
              <Row>
                <p>Nosivost</p>
                <p>kgF/KN</p>
                <CustomP>2000/20</CustomP>
                <CustomP>2500/25</CustomP>
                <CustomP>3000/30</CustomP>
                <CustomP>3500/35</CustomP>
                <CustomP>4000/40</CustomP>
              </Row>
              {/*Težište tereta*/}
              <Headline>Težište tereta</Headline>
              <Row>
                <p>Oslojanje zadnjeg dela viljuške</p>
                <p>mm</p>
                <CustomP>500</CustomP>
                <CustomP>500</CustomP>
                <CustomP>500</CustomP>
                <CustomP>500</CustomP>
                <CustomP>500</CustomP>
              </Row>
              {/*Karakteristike*/}
              <Headline>Karakteristike</Headline>
              <Row>
                <p>Maksimalna visina dizanja - h3</p>
                <p>mm</p>
                <CustomP>3000</CustomP>
                <CustomP>3000</CustomP>
                <CustomP>3000</CustomP>
                <CustomP>3000</CustomP>
                <CustomP>3000</CustomP>
              </Row>
              <Row>
                <p>Normalni slobodni hod - h.2</p>
                <p>mm</p>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
              </Row>
              {/*Viljuške*/}
              <Headline>Viljuške</Headline>
              <Row>
                <p>S/b/l</p>
                <p>mm</p>
                <CustomP>40/125/1150</CustomP>
                <CustomP>50/140/1150</CustomP>
                <CustomP>50/140/1150</CustomP>
                <CustomP>50/140/1150</CustomP>
                <CustomP>60/140/1150</CustomP>
              </Row>
              {/*Nagib*/}
              <Headline>Nagib</Headline>
              <Row>
                <p>Napred / nazad</p>
                <p>°</p>
                <CustomP>5°/12°</CustomP>
                <CustomP>5°/12°</CustomP>
                <CustomP>5°/12°</CustomP>
                <CustomP>5°/12°</CustomP>
                <CustomP>5°/12°</CustomP>
              </Row>
              {/*Dimenzije*/}
              <Headline>Dimenzije</Headline>
              <Row>
                <p>Dužina viljuski od čela viljuške - L2</p>
                <p>mm</p>
                <CustomP>2438</CustomP>
                <CustomP>2493</CustomP>
                <CustomP>2658</CustomP>
                <CustomP>2733</CustomP>
                <CustomP>2860</CustomP>
              </Row>
              <Row>
                <p>Širina</p>
                <p>mm</p>
                <CustomP>1222 max</CustomP>
                <CustomP>1222 max</CustomP>
                <CustomP>1222 max</CustomP>
                <CustomP>1222 max</CustomP>
                <CustomP>1725 max</CustomP>
              </Row>
              <Row>
                <p>Visina pri spuštenim viljuškama - h1 </p>
                <p>mm</p>
                <CustomP>2250 max</CustomP>
                <CustomP>2250 max</CustomP>
                <CustomP>2250 max</CustomP>
                <CustomP>2250 max</CustomP>
                <CustomP>2250 max</CustomP>
              </Row>
              <Row>
                <p>Visina pri maksimalnoj visini dizanja - h4</p>
                <p>mm</p>
                <CustomP>3860</CustomP>
                <CustomP>3860</CustomP>
                <CustomP>3860</CustomP>
                <CustomP>3860</CustomP>
                <CustomP>4075</CustomP>
              </Row>
              {/*Radijus okretanja*/}
              <Headline>Radijus okretanja</Headline>
              <Row>
                <p>Spoljni - Wa</p>
                <p>mm</p>
                <CustomP>2155</CustomP>
                <CustomP>2240</CustomP>
                <CustomP>2400</CustomP>
                <CustomP>2440</CustomP>
                <CustomP>26(X)</CustomP>
              </Row>
              {/*Širina radnog hodnika*/}
              <Headline>Širina radnog hodnika</Headline>
              <Row>
                <p>Sa paletom</p>
                <p>mm</p>
                <CustomP>3965</CustomP>
                <CustomP>4060</CustomP>
                <CustomP>4250</CustomP>
                <CustomP>4290</CustomP>
                <CustomP>4455</CustomP>
              </Row>
              {/*Brzina*/}
              <Headline>Brzina</Headline>
              <Row>
                <p>Sa teretom / bez tereta</p>
                <p>km/h</p>
                <CustomP>17</CustomP>
                <CustomP>17</CustomP>
                <CustomP>17</CustomP>
                <CustomP>17</CustomP>
                <CustomP>17</CustomP>
              </Row>
              <Row>
                <p>Dizanja sa teretom / bez tereta</p>
                <p>m/s</p>
                <CustomP>0.60/0.60</CustomP>
                <CustomP>0.60/0.60</CustomP>
                <CustomP>0.55/0.60</CustomP>
                <CustomP>0.55/0.60</CustomP>
                <CustomP>0.50/0.50</CustomP>
              </Row>
              <Row>
                <p>Spuštanje sa teretom / bez lereta</p>
                <p>m/s</p>
                <CustomP>0.60max/0.35min</CustomP>
                <CustomP>0.60max/0.35min</CustomP>
                <CustomP>0.60max/0.35min</CustomP>
                <CustomP>0.60max/0.35min</CustomP>
                <CustomP>0.60max/0.35min</CustomP>
              </Row>
              {/*Vučna sila*/}
              <Headline>Vučna sila</Headline>
              <Row>
                <p>Sa tovarom / bez lovara</p>
                <p>kN</p>
                <CustomP>19 min</CustomP>
                <CustomP>19 min</CustomP>
                <CustomP>19 min</CustomP>
                <CustomP>19 min</CustomP>
                <CustomP>19 min</CustomP>
              </Row>
              {/*Dozvoljen nagib*/}
              <Headline>Dozvoljen nagib</Headline>
              <Row>
                <p>Sa teretom / bez tereta</p>
                <p>%</p>
                <CustomP>26</CustomP>
                <CustomP>23</CustomP>
                <CustomP>20</CustomP>
                <CustomP>18</CustomP>
                <CustomP>16</CustomP>
              </Row>
              {/*Sopstvena težina*/}
              <Headline>Sopstvena težina</Headline>
              <Row>
                <p>Sa baterijom</p>
                <p>kg</p>
                <CustomP>3550</CustomP>
                <CustomP>3850</CustomP>
                <CustomP>4600</CustomP>
                <CustomP>4800</CustomP>
                <CustomP>5450</CustomP>
              </Row>
              {/*Osovinski pritisak*/}
              <Headline>Osovinski pritisak</Headline>
              <Row>
                <p> Sa teretom : prednji / zadnji</p>
                <p>kgf</p>
                <CustomP>5100max/500min</CustomP>
                <CustomP>5900max/600min</CustomP>
                <CustomP>7200max/600min</CustomP>
                <CustomP>7900max/600min</CustomP>
                <CustomP>9000max/700min</CustomP>
              </Row>
              {/*Gume*/}
              <Headline>Gume</Headline>
              <Row>
                <p>Prednje</p>
                <p>{""}</p>
                <CustomP>7.00 - 12</CustomP>
                <CustomP>7.00 - 12</CustomP>
                <CustomP>8.15 - 15</CustomP>
                <CustomP>8.15 - 15</CustomP>
                <CustomP>8.15 - 15</CustomP>
              </Row>
              <Row>
                <p>Zadnje</p>
                <p>{""}</p>
                <CustomP>6.00 - 9</CustomP>
                <CustomP>6.00 - 9</CustomP>
                <CustomP>6.50 - 10</CustomP>
                <CustomP>6.50 - 10</CustomP>
                <CustomP>6.50 - 10</CustomP>
              </Row>
              {/*Gabariti*/}
              <Headline>Gabariti</Headline>
              <Row>
                <p>U</p>
                <p>mm</p>
                <CustomP>1630</CustomP>
                <CustomP>1630</CustomP>
                <CustomP>1800</CustomP>
                <CustomP>1800</CustomP>
                <CustomP>1920</CustomP>
              </Row>
              {/*Širina*/}
              <Headline>Širina</Headline>
              <Row>
                <p>Izmedu prednjih i zadnjih</p>
                <p>mm</p>
                <CustomP>991/964.5</CustomP>
                <CustomP>991/964.5</CustomP>
                <CustomP>991/964.5</CustomP>
                <CustomP>991/964.5</CustomP>
                <CustomP>1240/961</CustomP>
              </Row>
              {/*Najniža tačka*/}
              <Headline>Najniža tačka</Headline>
              <Row>
                <p>Sa leretom najniža</p>
                <p>mm</p>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
                <CustomP>120 min</CustomP>
              </Row>
              <Row>
                <p>Sa leretom srednja</p>
                <p>mm</p>
                <CustomP>110 min</CustomP>
                <CustomP>110 min</CustomP>
                <CustomP>110 min</CustomP>
                <CustomP>110 min</CustomP>
                <CustomP>110 min</CustomP>
              </Row>
              {/*Baterija*/}
              <Headline>Baterija</Headline>
              <Row>
                <p>{""}</p>
                <p>V/Ah</p>
                <CustomP>12/135</CustomP>
                <CustomP>12/135</CustomP>
                <CustomP>12/135</CustomP>
                <CustomP>12/135</CustomP>
                <CustomP>12/135</CustomP>
              </Row>
              {/*Dizel motor*/}
              <Headline>Dizel motor</Headline>
              <Row>
                <p>Tip</p>
                <p>{""}</p>
                <CustomP>D3900K DM34i</CustomP>
                <CustomP>D3900K DM34i</CustomP>
                <CustomP>D3900K DM34i</CustomP>
                <CustomP>D3900K DM34i</CustomP>
                <CustomP>D3900K DM34i</CustomP>
              </Row>
              <Row>
                <p>Snaga</p>
                <p>kW</p>
                <CustomP>57.3</CustomP>
                <CustomP>57.3</CustomP>
                <CustomP>57.3</CustomP>
                <CustomP>57.3</CustomP>
                <CustomP>57.3</CustomP>
              </Row>
              <Row>
                <p>Brzina ohrtaja</p>
                <p>t/min</p>
                <CustomP>2250</CustomP>
                <CustomP>2250</CustomP>
                <CustomP>2250</CustomP>
                <CustomP>2250</CustomP>
                <CustomP>2250</CustomP>
              </Row>
              <Row>
                <p>Takt / broj cilindara. zapremina</p>
                <p>{""}</p>
                <CustomP>4/4/3860</CustomP>
                <CustomP>4/4/3860</CustomP>
                <CustomP>4/4/3860</CustomP>
                <CustomP>4/4/3860</CustomP>
                <CustomP>4/4/3860</CustomP>
              </Row>
              {/*Prenosni mehanizam*/}
              <Headline>Prenosni mehanizam</Headline>
              <Row>
                <p>Tip</p>
                <p>{""}</p>
                <TextRowBig>manuelni Hi hidrodinami-ki</TextRowBig>
                {/*<CustomPSmall>manuelni Hi hidrodinami-ki</CustomPSmall>*/}
                {/*<CustomPSmall>manuelni Hi hidrodinami-ki</CustomPSmall>*/}
                {/*<CustomPSmall>manuelni Hi hidrodinami-ki</CustomPSmall>*/}
                {/*<CustomPSmall>manuelni Hi hidrodinami-ki</CustomPSmall>*/}
                {/*<CustomPSmall>manuelni Hi hidrodinami-ki</CustomPSmall>*/}
              </Row>
              <Row>
                <p>Broj brzina: prednja / zadnja</p>
                <p>{""}</p>
                <CustomP>1/1</CustomP>
                <CustomP>1/1</CustomP>
                <CustomP>1/1</CustomP>
                <CustomP>1/1</CustomP>
                <CustomP>1/1</CustomP>
              </Row>
            </Table>
          ) : null}
        </Content>
      </Container>
    )
  }
}

export default ViljuskariPage

import dizel from "../images/dizel.png"
import elektro from "../images/elektro.png"

let viljuskari = {
  "Elektro Viljuskari": {
    name: "Elektro Viljuskari",
    src: elektro,
    header: {
      one: "tip",
      two: "nosivost",
      three: "teziste tereta",
      four: "uruedjaj za pokretanje",
      five: "upravljanje sistema",
      six: "gume",
      seven: "tockovi",
    },
    mere: {
      one: "\n ",
      two: "kg",
      three: "mm",
      four: " ",
      five: " ",
      six: " ",
      seven: " ",
    },
    item1: {
      one: "EV 1250 30",
      two: "1250",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item2: {
      one: "EV 1600 30",
      two: "1600",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item3: {
      one: "EV 2000 30",
      two: "2000",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item4: {
      one: "EV 2500 30",
      two: "2500",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
  },
  "Dizel Viljuskari": {
    name: "Dizel Viljuskari",
    src: dizel,
    header: {
      one: "tip",
      two: "nosivost",
      three: "teziste tereta",
      four: "Uredjaj za pokretanje",
      five: "upravljanje sistema",
      six: "gume",
      seven: "tockovi",
    },
    mere: {
      one: "\n ",
      two: "kg",
      three: "mm",
      four: "",
      five: "",
      six: "",
      seven: "",
    },
    item1: {
      one: "EV 1250 30",
      two: "1250",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item2: {
      one: "EV 1600 30",
      two: "1600",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item3: {
      one: "EV 2000 30",
      two: "2000",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
    item4: {
      one: "EV 2500 30",
      two: "2500",
      three: "500",
      four: "baterija",
      five: "sa sedista vozaca",
      six: "super elastik",
      seven: "4 komada",
    },
  },
}

export default viljuskari
